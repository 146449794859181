import './App.css';
import comingSoonImage from './coming-soon.png';

function App() {
    return (
        <div className='container'>
            <div className='coming-soon'>
                <img src={comingSoonImage} alt='coming-soon' className='image' />
                <h1>Coming soon!</h1>
            </div>
        </div>
    );
}

export default App;
